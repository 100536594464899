import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sentenceCase } from 'change-case';

interface PrizeList {
  prize_1: Pick<any, string | number | symbol>[];
  prize_2: Pick<any, string | number | symbol>[];
}

const Winners: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    prize_1: [],
    prize_2: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');

    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      prize_1: prizes.prize_1 === undefined ? [] : prizes.prize_1,
      prize_2: prizes.prize_2 === undefined ? [] : prizes.prize_2,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <div className="Winners">
      <div className="winners-header">
        <h2>{t('winners')}</h2>
      </div>
      <div className="winners-content">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <ul className={winners.prize_1.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('prize 1 winners')}</li>
              {winners.prize_1.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })}
              <li>Bob Smith</li>
              <li>Alice Smith</li>
            </ul>
          </div>
          <div className="column is-narrow">
            <ul className={winners.prize_2.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('prize 2 winners')}</li>
              {winners.prize_2.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })}
              <li>Bob Smith</li>
              <li>Alice Smith</li>
              <li>Bob Smith</li>
              <li>Alice Smith</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Winners;
