import React, { ReactElement, useState } from 'react';
import './Navigation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navigation: React.FC = (): ReactElement => {
  const [open, toggleOpen] = useState(false);

  const [animation, setAnimation] = useState('nav-slide-in-right');

  const toggleElementOpen = () => {
    setAnimation(open ? 'nav-slide-out-right' : 'nav-slide-in-right');

    setTimeout(
      () => {
        toggleOpen(!open);
      },
      open ? 1100 : 0
    );
  };
  const { t } = useTranslation();
  return (
    <div className="Navigation">
      {/* if nav open=true render the menu */}
      {open && (
        <div
          className={
            'nav-menu columns is-mobile is-centered is-vcentered ' + animation
          }
        >
          <ul className="column is-narrow">
            <li>
              <Link to="/" onClick={() => toggleElementOpen()}>
                {t('home')}
              </Link>
            </li>
            <li>
              <Link to="/register" onClick={() => toggleElementOpen()}>
                {t('register')}
              </Link>
            </li>
            <li>
              <Link to="/winners" onClick={() => toggleElementOpen()}>
                {t('winners')}
              </Link>
            </li>
            <li>
              <Link to="/rules" onClick={() => toggleElementOpen()}>
                {t('rules')}
              </Link>
            </li>
          </ul>
        </div>
      )}
      <button onClick={() => toggleElementOpen()} className="nav-button">
        <FontAwesomeIcon
          icon={open ? faTimes : faBars}
          className="has-text-primary"
        />
      </button>
    </div>
  );
};

export default Navigation;
