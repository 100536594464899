import React, { ReactElement } from 'react';
import './Main.scss';
import Logo from '../../assets/images/banana_logo.png';
import Banner from '../../assets/images/banana_banner.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <div className="logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="banner-container">
        <div className="banner-image">
          <img src={Banner} alt="" />
        </div>
      </div>
      <div className="description-container container">
        <div className="campaign-description">{t('campaign description')}</div>
        <div className="campaign-period">
          <span>{t('campaign period')}</span>
        </div>
      </div>
      <div className="nav-container container">
        <Link to="/register" className="button">
          {t('register')}
        </Link>
      </div>
    </div>
  );
};

export default Main;
