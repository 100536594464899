import React, { ReactElement } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Register from './views/register/Register';
import Rules from './views/Rules/Rules';
import Winners from './views/winners/Winners';
import Thanks from './views/Thanks/Thanks';
import Main from './views/Main/Main';
import Navigation from './components/navigation/Navigation';
import usePageTracking from './components/page-tracking/usePageTracking';
import Footer from './components/footer/Footer';

const App: React.FC = (): ReactElement => {
  usePageTracking();
  return (
    <div className="App">
      {/* <Main /> */}
      <Navigation />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/register" element={<Register />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
