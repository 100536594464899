import React, { ReactElement } from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <section className="footer">
      <div className="container">
        <span>{t('copyright')}</span>
      </div>
    </section>
  );
};

export default Footer;
