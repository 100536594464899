import React, { ReactElement } from 'react';
import './Rules.scss';

const En: React.FC = (): ReactElement => {
  return (
    <div className="En rules-wrapper">
      <ol>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna. Maecenas dapibus nisl et est molestie dapibus. Sed
          pulvinar, ipsum sodales fringilla suscipit, risus diam accumsan diam,
          nec venenatis ex nibh nec neque.
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna. Maecenas dapibus nisl et est molestie dapibus. Sed
          pulvinar, ipsum sodales fringilla suscipit, risus diam accumsan diam,
          nec venenatis ex nibh nec neque.
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna:
          <ol>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              in dignissim magna. Maecenas dapibus nisl et est molestie dapibus.
              Sed pulvinar, ipsum sodales fringilla suscipit, risus diam
              accumsan diam, nec venenatis ex nibh nec neque.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              in dignissim magna. Maecenas dapibus nisl et est molestie dapibus.
              Sed pulvinar, ipsum sodales fringilla suscipit, risus diam
              accumsan diam, nec venenatis ex nibh nec neque.
            </li>
          </ol>
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna. Maecenas dapibus nisl et est molestie dapibus. Sed
          pulvinar, ipsum sodales fringilla suscipit, risus diam accumsan diam,
          nec venenatis ex nibh nec neque.
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna:
          <ol>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              in dignissim magna. Maecenas dapibus nisl et est molestie dapibus.
              Sed pulvinar, ipsum sodales fringilla suscipit, risus diam
              accumsan diam, nec venenatis ex nibh nec neque.
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              in dignissim magna. Maecenas dapibus nisl et est molestie dapibus.
              Sed pulvinar, ipsum sodales fringilla suscipit, risus diam
              accumsan diam, nec venenatis ex nibh nec neque.
            </li>
          </ol>
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus in
          dignissim magna. Maecenas dapibus nisl et est molestie dapibus. Sed
          pulvinar, ipsum sodales fringilla suscipit, risus diam accumsan diam,
          nec venenatis ex nibh nec neque.
        </li>
      </ol>
    </div>
  );
};

export default En;
